import axios from 'axios';
import getCookie from './getCookie';

export const cart = axios.create({
  baseURL: '/api/v1/ncpl/cart',
  headers: {
    'x-ncpl-rcsrf': getCookie('x-ncpl-csrf'),
  },
});

export const getCartItems = () =>
  process.env.NODE_ENV === 'development'
    ? Promise.resolve([])
    : cart
        .get('/user/get')
        .then(
          response =>
            response.data?.Items?.map(item => item.Id.split(':')[1]) ?? [],
        );

export const addToCart = async sale => {
  const isAftermarketDomain = ['afternic', 'sedo'].includes(sale.inventory);
  await cart
    .post('/user/additems', {
      Items: [
        {
          Key: isAftermarketDomain
            ? `${Date.now()}:aftermarket=True:${sale.product.name}`
            : `${Date.now()}:${sale.product.name}`,
          Product: isAftermarketDomain
            ? 'aftermarketmarketplace'
            : 'ncmarketplace',
          Action: 'PURCHASE',
          Duration: {
            Value: 1,
            DurationType: 'YEAR',
          },
          Qty: 1,
          Enabled: true,
        },
      ],
    })
    .then(({ data }) => {
      if (['ERROR', 'VALIDATION_ERROR'].includes(data[0]?.MessageKey)) {
        throw new Error(data[0].LocalizedMessage);
      }
    });
};

export const newsletter = axios.create({
  baseURL: '/api/v1/ncpl/csmessages/iterable',
  headers: {
    'x-ncpl-rcsrf': getCookie('x-ncpl-csrf'),
  },
});

export const newsletterSignUp = async email => {
  const {
    data: { Token: token },
  } = await newsletter.post('/registeremail', {
    Email: email,
  });
  await newsletter.post('/sendevent', {
    token,
    eventName: 'signedUp',
    payload: JSON.stringify({
      creationDate: new Date(),
      productName: 'Namecheap Market',
      url: 'namecheap.com/market',
    }),
  });
};
