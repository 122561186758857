import cx from 'classnames';
import { Link } from 'react-router-dom';
import tldSprite from '../assets/tld-sprite.svg';
import getSalePresenter from '../lib/getSalePresenter';
import useCarousel from '../lib/useCarousel';
import Card, { CardTitle, CardFooter } from './Card';
import TimeLeft from './TimeLeft';

export default function SaleCarousel({
  className = '',
  containerClassName = 'tw-px-4 tw-grid-cols-[1fr_min(100%,1440px)_1fr]',
  heading,
  link,
  saleLinkRef,
  sales,
  showSeeAllLink,
  subheading = null,
  theme,
}) {
  const {
    getLeftNavProps,
    getRightNavProps,
    isTouchDevice,
    scrollAreaRef,
    scrollPosition,
    showNav,
    slideRef,
  } = useCarousel();

  if (!sales || sales.length === 0) return null;

  return (
    <section className={className}>
      <div className="tw-overflow-hidden tw-pt-1">
        <div className="xl:tw-grid tw--mb-4">
          <div
            className={cx('xl:tw-grid tw-col-span-full', containerClassName)}>
            <div className="tw-flex tw-col-start-2 tw-items-center tw-justify-between">
              <Link className="tw-pr-4 sm:tw-pr-8 tw-no-underline" to={link}>
                <h2 className="tw-text-2xl lg:tw-text-3xl tw-text-gray-700 tw-font-bold">
                  {heading}
                </h2>
                {subheading && (
                  <p className="tw-text-sm sm:tw-text-base tw-text-gray-600 sm:tw-my-2">
                    {subheading}
                  </p>
                )}
              </Link>
              <div className="tw-flex tw-items-center">
                {showSeeAllLink && (
                  <Link className="gb-btn gb-btn--sm tw-min-w-0" to={link}>
                    <span className="tw-hidden lg:tw-inline">
                      See all {heading}
                    </span>
                    <span className="tw-inline lg:tw-hidden">See All</span>
                  </Link>
                )}
                <button
                  disabled={scrollPosition === 'start'}
                  className={cx('tw-p-1 tw-ml-4 active:tw-text-black', {
                    'tw-hidden': isTouchDevice || !showNav,
                    'tw-text-gray-400': scrollPosition === 'start',
                    'hover:tw-text-gray-700': scrollPosition !== 'start',
                  })}
                  {...getLeftNavProps()}>
                  <i className="gb-icon gb-icon-morpheus-arrow-back tw-flex tw-text-2xl" />
                </button>
                <button
                  disabled={scrollPosition === 'end'}
                  className={cx('tw-p-1 active:tw-text-black', {
                    'tw-hidden': isTouchDevice || !showNav,
                    'tw-text-gray-400': scrollPosition === 'end',
                    'hover:tw-text-gray-700': scrollPosition !== 'end',
                  })}
                  {...getRightNavProps()}>
                  <i className="gb-icon gb-icon-morpheus-arrow-back tw-text-2xl tw-flex tw-rotate-180" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={cx(
              'xl:tw-grid tw-col-span-full tw-overflow-x-scroll tw-overscroll-x-contain tw-pt-4 tw-pb-8',
              containerClassName,
            )}
            ref={scrollAreaRef}>
            <div className="tw-flex tw-col-start-2">
              {sales.map((sale, index) => {
                const presenter = getSalePresenter(sale);
                return (
                  <div
                    className="tw-box-content tw-shrink-0 tw-w-56 lg:tw-w-64 tw-pl-4 first:tw-pl-0"
                    key={index}
                    ref={index === 0 ? slideRef : undefined}>
                    <Card
                      className="tw-h-full"
                      link={
                        presenter.permalink +
                        (saleLinkRef && saleLinkRef !== 'auctions'
                          ? `?ref=${saleLinkRef}`
                          : '')
                      }>
                      <img
                        alt=""
                        className="tw-w-8 tw-h-8 tw-mb-2"
                        src={`${tldSprite}#${presenter.tld}`}
                      />
                      <CardTitle>{presenter.nameFormatted}</CardTitle>
                      {presenter.endDate && (
                        <div className="tw-font-light">
                          <TimeLeft
                            endedText="Ended"
                            suffix="left"
                            until={presenter.endDate}
                          />
                        </div>
                      )}
                      <CardFooter className="tw-relative">
                        <div>
                          {presenter.priceFormatted}
                          {presenter.saleType === 'auction' && (
                            <div className="tw-text-xs">
                              {presenter.bidCount}{' '}
                              {presenter.bidCount === 1 ? 'bid' : 'bids'}
                            </div>
                          )}
                        </div>
                        <div
                          className={cx(
                            'gb-btn gb-btn--primary gb-btn--sm tw-min-w-0 tw-scale-75 tw-origin-bottom-right tw-flex-shrink-0 tw-absolute tw-right-0 tw-bottom-0',
                            {
                              'gb-btn--primary': theme === 'primary',
                              'gb-btn--secondary': theme === 'secondary',
                            },
                          )}>
                          {presenter.isEnded
                            ? 'View Now'
                            : presenter.saleType === 'auction'
                            ? 'Bid Now'
                            : 'Buy Now'}
                        </div>
                      </CardFooter>
                    </Card>
                  </div>
                );
              })}
              {showSeeAllLink && (
                <div className="tw-px-4">
                  <Card className="tw-h-full tw-justify-center" link={link}>
                    <div className="tw-flex tw-items-center tw-whitespace-nowrap tw-font-bold tw-text-gray-600 tw-px-4 tw-no-underline">
                      See all domains{' '}
                      <i className="gb-icon gb-icon-morpheus-arrow-back tw-flex tw-rotate-180 tw-ml-2" />
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
