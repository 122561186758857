import { sha256 } from 'crypto-hash';
import { print } from 'graphql';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import sortDocumentDefinitions from '@nc-aftermarket/shared/utils/sortDocumentDefinitions';
import { openAppDialog } from '../components/AppDialog';
import isInvalidQueryError from './isInvalidQueryError';
import getAuthRedirectUrl from './getAuthRedirectUrl';

const retryQueries = ['MarketHome'];

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (_error, operation) =>
      retryQueries.includes(operation.operationName),
  },
});

const queryLink = createPersistedQueryLink({
  disable: () => false,
  generateHash: query => {
    const shallowCopy = {
      ...query,
      definitions: [...query.definitions],
    };
    shallowCopy.definitions.sort(sortDocumentDefinitions);
    return sha256(print(shallowCopy));
  },
});

const httpLink = createHttpLink({
  credentials: 'include',
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
});

const errorLink = onError(error => {
  const { graphQLErrors, networkError } = error;

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);

  if (graphQLErrors?.[0]?.message.includes('ERR_REAUTH_REQUIRED')) {
    window.location.href = getAuthRedirectUrl(window.location.pathname);
  }

  if (isInvalidQueryError(error)) {
    openAppDialog('invalidQuery');
  }
});

const paginatedFieldMerge = (existing = {}, incoming, options) => {
  const { page, pageSize } = options.args;
  const offset = (page - 1) * pageSize;
  const newItems = [...(existing.items ?? [])];
  for (let i = 0; i < incoming.items?.length ?? 0; ++i) {
    newItems[offset + i] = incoming.items[i];
  }
  return {
    ...incoming,
    items: newItems,
  };
};

const paginatedFieldKeyArgs = ['filter', 'sort'];

export default function getApolloClient() {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Collection: {
          fields: {
            sales: {
              keyArgs: paginatedFieldKeyArgs,
              merge: paginatedFieldMerge,
            },
          },
        },
        Product: {
          keyFields: ['name'],
        },
        Query: {
          fields: {
            binSales: {
              keyArgs: paginatedFieldKeyArgs,
              merge: paginatedFieldMerge,
            },
            sales: {
              keyArgs: paginatedFieldKeyArgs,
              merge: paginatedFieldMerge,
            },
          },
        },
        User: {
          fields: {
            bidding: {
              keyArgs: paginatedFieldKeyArgs,
              merge: paginatedFieldMerge,
            },
            invoices: {
              keyArgs: paginatedFieldKeyArgs,
              merge: paginatedFieldMerge,
            },
            favorites: {
              keyArgs: paginatedFieldKeyArgs,
              merge: paginatedFieldMerge,
            },
          },
        },
        UserDisplayName: {
          keyFields: ['value'],
        },
      },
    }),
    connectToDevTools: true,
    link: from([retryLink, errorLink, queryLink, httpLink]),
  });
}
