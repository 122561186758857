import _isEqual from 'lodash/isEqual';
import { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import getSalePresenter from '../lib/getSalePresenter';
import columnDefs, { SaleTableColumnSets } from './SaleTable.columns';

// Create a shared context for entire family of SaleTable sub-components
const SaleTableContext = createContext({});

export function useSaleTable() {
  return useContext(SaleTableContext);
}

export default function SaleTable({
  children,
  defaultState = {},
  initialState,
  loading,
  sales,
  total = null,
  visibleColumns = SaleTableColumnSets.default,
}) {
  const [cachedInitialState] = useState(initialState);
  // Parse and memoize domain data and columns for react-table
  const data = useMemo(
    () => sales?.map(sale => getSalePresenter(sale)) ?? [],
    [sales],
  );

  const [columns, hiddenColumns] = useMemo(() => {
    const sortedVisible = columnDefs.concat().sort((a, b) => {
      const indexOfA = visibleColumns.indexOf(a.id);
      const indexOfB = visibleColumns.indexOf(b.id);
      if (indexOfA === -1) {
        return 1;
      } else if (indexOfB === -1) {
        return -1;
      } else {
        return indexOfA - indexOfB;
      }
    });
    const hidden = columnDefs
      .map(def => def.id)
      .filter(column => visibleColumns.indexOf(column) === -1);
    return [sortedVisible, hidden];
  }, [visibleColumns]);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const instance = useTable(
    {
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSelectedRows: false,
      columns,
      data,
      disableMultiSort: true,
      initialState: {
        ...cachedInitialState,
        hiddenColumns,
      },
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
  );

  const { globalFilter, filters, sortBy } = instance.state;

  // Store table state in location
  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (globalFilter && globalFilter !== defaultState['globalFilter']) {
      searchParams.set('query', globalFilter);
    }
    if (filters.length > 0) {
      searchParams.set('filter', btoa(JSON.stringify(filters)));
    }
    if (
      sortBy.length > 0 &&
      btoa(JSON.stringify(sortBy)) !==
        btoa(JSON.stringify(defaultState['sortBy']))
    ) {
      searchParams.set('sort', btoa(JSON.stringify(sortBy)));
    }
    const newLocation = {
      hash: location.hash,
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : '',
      state: location.state,
    };
    if (
      location.hash !== newLocation.hash ||
      location.pathname !== newLocation.pathname ||
      location.search !== newLocation.search ||
      !_isEqual(location.state, newLocation.state)
    ) {
      history.replace(newLocation);
    }
  }, [
    defaultState,
    filters,
    globalFilter,
    history,
    location.hash,
    location.pathname,
    location.search,
    location.state,
    match.url,
    sortBy,
  ]);

  return (
    <SaleTableContext.Provider
      value={{
        ...instance,
        loading,
        totalItems: total,
      }}>
      {children}
    </SaleTableContext.Provider>
  );
}
