import MUIModal from '@mui/material/Modal';

export default function Modal({ children, onClose, ...props }) {
  return (
    <MUIModal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      container={document.querySelector('#root')}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onClose(event);
      }}
      open={props.open}
      slotProps={{
        backdrop: {
          style: { background: 'var(--gb-modal-overlay)' },
        },
      }}
      {...props}>
      <div className="gb-modal__surface focus:tw-outline-none">
        <button className="gb-modal__close" onClick={onClose}>
          Close
        </button>
        {children}
      </div>
    </MUIModal>
  );
}

export const ModalTitle = props => (
  <h2
    className="tw-text-2xl tw-font-bold tw-text-gray-700 tw-mb-2"
    id="modal-title">
    {props.children}
  </h2>
);

export const ModalActions = ({
  align = 'center',
  justify = 'end',
  children,
}) => (
  <div className={`tw-flex tw-mt-6 tw-items-${align} tw-justify-${justify}`}>
    {children}
  </div>
);
