const muiTheme = {
  palette: {
    action: {
      hover: '#f2f2f2',
      focus: '#f2f2f2',
      selected: '#f2f2f2',
      selectedOpacity: 1,
    },
    info: {
      main: '#579fe7',
    },
    primary: {
      main: '#de3723',
    },
    secondary: {
      main: '#8cc1c1',
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#c4c4c4',
    },
  },
  overrides: {
    MuiAlertTitle: {
      root: {
        fontWeight: 'bold',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#aeaeae',
        },
      },
      notchedOutline: {
        borderColor: '#eee',
      },
    },
    MuiPaginationItem: {
      page: {
        transition: 'none',
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  spacing: 4,
  typography: {
    fontFamily: 'GB Museo Sans, Arial, Helvetica, sans-serif',
  },
};

export default muiTheme;
