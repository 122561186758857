Object.defineProperty(exports, '__esModule', {
  value: true,
});

module.exports = function formatCurrency(number, digits = 2) {
  return (
    '$' +
    number
      .toFixed(digits)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      .replace(/\.00$/, '')
  );
};
