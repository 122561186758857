import { loader } from 'graphql.macro';

const queryDefs = {
  breadcrumbsCollection: loader(
    '@nc-aftermarket/shared/queries/BreadcrumbsCollection.gql',
  ),
  buyNowTable: loader('@nc-aftermarket/shared/queries/BuyNowTable.gql'),
  collectionQuery: loader(
    '@nc-aftermarket/shared/queries/SalePageCollection.gql',
  ),
  collectionTable: loader('@nc-aftermarket/shared/queries/CollectionTable.gql'),
  createBid: loader('@nc-aftermarket/shared/queries/CreateBid.gql'),
  createComment: loader('@nc-aftermarket/shared/queries/CreateComment.gql'),
  createToken: loader('@nc-aftermarket/shared/queries/CreateAPIToken.gql'),
  deleteComment: loader('@nc-aftermarket/shared/queries/DeleteComment.gql'),
  deleteToken: loader('@nc-aftermarket/shared/queries/DeleteAPIToken.gql'),
  dismissMessage: loader('@nc-aftermarket/shared/queries/DismissMessage.gql'),
  enableSmsNotifications: loader(
    '@nc-aftermarket/shared/queries/EnableSmsNotifications.gql',
  ),
  favorite: loader('@nc-aftermarket/shared/queries/Favorite.gql'),
  flagComment: loader('@nc-aftermarket/shared/queries/FlagComment.gql'),
  incrementViewCount: loader(
    '@nc-aftermarket/shared/queries/IncrementSaleViewCount.gql',
  ),
  invoices: loader('@nc-aftermarket/shared/queries/UserInvoices.gql'),
  invoicesMeta: loader('@nc-aftermarket/shared/queries/UserInvoicesMeta.gql'),
  like: loader('@nc-aftermarket/shared/queries/Like.gql'),
  login: loader('@nc-aftermarket/shared/queries/LoginWithAuthCode.gql'),
  logout: loader('@nc-aftermarket/shared/queries/Logout.gql'),
  marketHome: loader('@nc-aftermarket/shared/queries/MarketHome.gql'),
  marketStats: loader('@nc-aftermarket/shared/queries/MarketStats.gql'),
  salePage: loader('@nc-aftermarket/shared/queries/SalePage.gql'),
  salePageBin: loader('@nc-aftermarket/shared/queries/SalePageBIN.gql'),
  salesRealtimeSync: loader(
    '@nc-aftermarket/shared/queries/SalesEndingSoonRealtimeSync.gql',
  ),
  saleSubscribe: loader('@nc-aftermarket/shared/queries/SaleSubscribe.gql'),
  saleTable: loader('@nc-aftermarket/shared/queries/SaleTable.gql'),
  settings: loader('@nc-aftermarket/shared/queries/UserSettings.gql'),
  subscribe: loader('@nc-aftermarket/shared/queries/Subscribe.gql'),
  subscribeConfirm: loader(
    '@nc-aftermarket/shared/queries/SubscribeConfirm.gql',
  ),
  updateDisplayName: loader(
    '@nc-aftermarket/shared/queries/UpdateDisplayName.gql',
  ),
  updateWebhook: loader('@nc-aftermarket/shared/queries/UpdateWebhook.gql'),
  userBidding: loader('@nc-aftermarket/shared/queries/UserBidding.gql'),
  userContext: loader('@nc-aftermarket/shared/queries/UserContext.gql'),
  userFavorites: loader('@nc-aftermarket/shared/queries/UserFavorites.gql'),
  userInvoicesMeta: loader(
    '@nc-aftermarket/shared/queries/UserInvoicesMeta.gql',
  ),
  verifySMS: loader('@nc-aftermarket/shared/queries/VerifySMS.gql'),
  watch: loader('@nc-aftermarket/shared/queries/Watch.gql'),
};

export default queryDefs;
