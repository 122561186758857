import cx from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

export default function TimeLeft({ endedText = '', suffix = '', until }) {
  const now = new Date();
  const untilDate = new Date(until);
  const diffMs = untilDate.getTime() - now.getTime();
  const isEndingSoon = diffMs > 0 && diffMs <= 30 * 60 * 1000;
  const [, setUpdateCount] = useState(0);
  const [refreshRate, setRefreshRate] = useState(60);
  const interval = useRef();

  useEffect(() => {
    if (interval.current) window.clearInterval(interval.current);
    interval.current = window.setInterval(() => {
      setUpdateCount(x => x + 1);
    }, refreshRate * 1000);
    return () => window.clearInterval(interval.current);
  }, [setUpdateCount, refreshRate]);

  useEffect(() => {
    setRefreshRate(isEndingSoon ? 5 : 60);
  }, [isEndingSoon]);

  const formatted = dayjs().to(dayjs(untilDate), true);

  return (
    <span
      className={cx({ 'tw-text-red-500': isEndingSoon })}
      title={`Until ${untilDate}`}>
      {untilDate > now
        ? `${formatted} ${suffix}`
        : `${endedText} ${formatted} ago`}
    </span>
  );
}
