import { makeVar } from '@apollo/client';
import _debounce from 'lodash/debounce';
import { getCartItems } from './api';

const cartItemsVar = makeVar([]);

export const syncCartItems = _debounce(async () => {
  cartItemsVar(await getCartItems());
}, 1000);

document.addEventListener('shoppingCart.remove.success', () => {
  syncCartItems();
});

export default cartItemsVar;
