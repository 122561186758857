import cx from 'classnames';
import { forwardRef } from 'react';

const TextInput = forwardRef(
  (
    /** @type {any} */
    {
      className = '',
      inputClassName = '',
      innerLeft = null,
      innerRight = null,
      outline = true,
      size,
      ...inputProps
    },
    ref,
  ) => (
    <div className={cx(className, 'tw-relative tw-flex')}>
      {innerLeft && (
        <div className="tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-flex tw-items-center">
          {innerLeft}
        </div>
      )}
      <input
        className={cx(inputClassName, 'gb-form-control', {
          'gb-form-control--sm': size === 'small',
        })}
        {...inputProps}
        ref={ref}
      />
      {innerRight && (
        <div className="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-flex tw-items-center">
          {innerRight}
        </div>
      )}
    </div>
  ),
);

export default TextInput;
