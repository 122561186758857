import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import App from './App';
import Loader from './Loader';

const LandingPage = lazy(() => import('./LandingPage'));

export default function AppGateway() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/ppc">
          <LandingPage />
        </Route>
        <Route>
          <App />
        </Route>
      </Switch>
    </Suspense>
  );
}
