export default function getSaleCarouselPropsFromCollection(collection) {
  const { heading, slug, subheading, theme } = collection;
  return {
    heading,
    link: `/${slug}/`,
    saleLinkRef: collection.slug,
    sales: collection.sales.items,
    showSeeAllLink: true,
    subheading,
    theme,
  };
}
