import dayjs from 'dayjs';
import { lazy, useEffect, useState, Suspense } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import formatCurrency from '../lib/formatCurrency';
import queryDefs from '../lib/queryDefs';
import Loader from './Loader';
import Modal, { ModalActions, ModalTitle } from './Modal';

const DisplayNameForm = lazy(() => import('./DisplayNameForm'));
const VerifySMSForm = lazy(() => import('./VerifySMSForm'));
const pkgName = process.env.REACT_APP_PACKAGE_NAME;

const eventKeys = {
  open: `${pkgName}:AppDialog:open`,
};

/**
 * @param {string} key
 * @param {{ [key: string]: any }=} data
 */
export function openAppDialog(key, data) {
  document.dispatchEvent(
    new CustomEvent(eventKeys.open, { detail: { key, ...data } }),
  );
}

const initialState = {
  data: null,
  key: null,
  open: false,
};

export default function AppDialog() {
  const [state, setState] = useState({
    ...initialState,
  });

  useEffect(() => {
    const listener = event => {
      const { key, ...data } = event.detail;

      setState({
        data,
        key,
        open: true,
      });

      if (process.env.NODE_ENV === 'development') {
        console.log(`${eventKeys.open} listener fired`);
        console.table(event.detail);
      }
    };

    document.addEventListener(eventKeys.open, listener);

    return () => document.removeEventListener(eventKeys.open, listener);
  }, []);

  const onClose = () =>
    setState({
      ...initialState,
    });

  const modalProps = {
    data: state.data,
    onClose,
  };

  return (
    <Modal onClose={onClose} open={state.open}>
      <Suspense fallback={<Loader className="tw-py-12" />}>
        {state.key === 'bidSuccessLeading' ? (
          <LeadingBidder {...modalProps} />
        ) : state.key === 'outbid' ? (
          <Outbid {...modalProps} />
        ) : state.key === 'bidSuccessOutbid' ? (
          <OutbidProxy {...modalProps} />
        ) : state.key === 'subscriptionDisclosure' ? (
          <SubscriptionDisclosure {...modalProps} />
        ) : state.key === 'invalidQuery' ? (
          <InvalidQuery {...modalProps} />
        ) : state.key === 'solicitDisplayName' ? (
          <SolicitDisplayName {...modalProps} />
        ) : state.key === 'solicitDisplayNameForComment' ? (
          <SolicitDisplayNameForComment {...modalProps} />
        ) : state.key === 'phoneVerifyForm' ? (
          <VerifySMSForm {...modalProps} />
        ) : state.key === 'failedToAddDomainToCart' ? (
          <FailedToAddDomainToCart {...modalProps} />
        ) : null}
      </Suspense>
    </Modal>
  );
}

const LeadingBidder = ({ data: { bid, sale }, onClose }) => {
  const bidAmount = formatCurrency(bid.maxAmount);
  return (
    <>
      <ModalTitle>
        Congratulations, you’re the leading bidder on{' '}
        <b className="tw-text-black">{sale.product.name}</b>!
      </ModalTitle>
      <p id="modal-description">
        We’ve successfully received your bid for {bidAmount} on{' '}
        {sale.product.name}.
      </p>
      {bid.maxAmount > sale.minBid && (
        <>
          <h3 className="tw-text-xl tw-font-bold tw-mt-4 tw-mb-2">
            What happens next?
          </h3>
          <p>
            You’re currently the leading bidder on {sale.product.name}. Because
            your bid is above the domain’s current minimum bid of{' '}
            {formatCurrency(sale.minBid)}, we will automatically bid for you up
            until your bid amount of {bidAmount}. If someone outbids you, we’ll
            send you an email immediately in case you want to make another bid.
          </p>
          <p className="tw-mt-2">
            Learn more about how bidding works in the{' '}
            <a
              href="https://www.namecheap.com/support/knowledgebase/article.aspx/10522/15/auctions-bidding-guide/"
              rel="noreferrer"
              target="_blank">
              auction bidding guide
            </a>
            .
          </p>
        </>
      )}
      {bid.isSnipe && <BidSnipeText sale={sale} />}
      <ModalActions align="end" justify="between">
        <DismissButton messageId="bid_confirmation" onClose={onClose}>
          Don’t show this again
        </DismissButton>
        <button
          autoFocus
          className="tw-px-4 gb-btn gb-btn--lg gb-btn--primary gb-btn--sm"
          onClick={onClose}>
          Okay
        </button>
      </ModalActions>
    </>
  );
};

const Outbid = ({ data: { sale }, onClose }) => {
  return (
    <>
      <ModalTitle>
        You’ve just been outbid on{' '}
        <b className="tw-text-black">{sale.product.name}</b>!
      </ModalTitle>
      <p className="tw-mb-2" id="modal-description">
        Another bidder has just become the leading bidder on {sale.product.name}{' '}
        by placing a bid higher than your previous maximum bid. Try bidding
        again with a higher amount if you want to win this domain.
      </p>
      <p className="tw-mt-2">
        Learn more about how bidding works in the{' '}
        <a
          href="https://www.namecheap.com/support/knowledgebase/article.aspx/10522/15/auctions-bidding-guide/"
          rel="noreferrer"
          target="_blank">
          auction bidding guide
        </a>
        .
      </p>
      <ModalActions>
        <button className="gb-btn gb-btn--lg tw-mr-2" onClick={onClose}>
          Cancel
        </button>
        <Link
          autoFocus
          className="gb-btn gb-btn--lg gb-btn--primary"
          to={`/${sale.product.name}/`}
          onClick={onClose}>
          Bid Again
        </Link>
      </ModalActions>
    </>
  );
};

const OutbidProxy = ({ data: { bid, sale }, onClose }) => {
  const bidAmount = formatCurrency(bid.maxAmount);
  return (
    <>
      <ModalTitle>You’ve been outbid by another bidder</ModalTitle>
      <p id="modal-description">
        We’ve successfully received your bid for {bidAmount} on{' '}
        {sale.product.name}. However you’ve been outbid by another bidder.
      </p>
      <h3 className="tw-text-xl tw-font-bold tw-text-gray-700 tw-mt-4 tw-mb-2">
        How did this happen?
      </h3>
      <p>
        Another user has set their maximum bid on {sale.product.name} to an
        amount higher than or equal to your bid of {bidAmount}, so we’ve
        automatically bid again on their behalf. If you’d like to become the
        leading bidder, try bidding again with a higher amount.
      </p>
      {bid.isSnipe && <BidSnipeText sale={sale} />}
      <p className="tw-mt-2">
        Learn more about how bidding works in the{' '}
        <a
          href="https://www.namecheap.com/support/knowledgebase/article.aspx/10522/15/auctions-bidding-guide/"
          rel="noreferrer"
          target="_blank">
          auction bidding guide
        </a>
        .
      </p>
      <ModalActions>
        <button className="gb-btn gb-btn--lg tw-mr-2" onClick={onClose}>
          Cancel
        </button>
        <Link
          autoFocus
          className="gb-btn gb-btn--lg gb-btn--primary"
          to={`/${sale.product.name}/`}
          onClick={onClose}>
          Bid Again
        </Link>
      </ModalActions>
    </>
  );
};

const SubscriptionDisclosure = ({ data: { returnTo }, onClose }) => {
  const history = useHistory();
  return (
    <>
      <ModalTitle>Subscribe to Namecheap Aftermarket</ModalTitle>
      <p id="modal-description">
        Before you can place a bid, you must first subscribe to the Namecheap
        Aftermarket.
      </p>
      <ModalActions>
        <button
          autoFocus
          className="gb-btn gb-btn--lg tw-mr-2"
          onClick={onClose}>
          Cancel
        </button>
        <button
          autoFocus
          className="gb-btn gb-btn--lg gb-btn--primary"
          onClick={() => {
            onClose();
            history.push(`/subscribe/?returnTo=${returnTo}`);
          }}>
          Subscribe
        </button>
      </ModalActions>
    </>
  );
};

const InvalidQuery = ({ onClose }) => {
  return (
    <>
      <ModalTitle>There was a problem</ModalTitle>
      <p id="modal-description">
        A server error occurred. Please press 'Reload' in your browser.
      </p>
      <ModalActions>
        <button
          autoFocus
          className="gb-btn gb-btn--lg gb-btn--primary tw-px-8"
          onClick={onClose}>
          OK
        </button>
      </ModalActions>
    </>
  );
};

const BidSnipeText = ({ sale }) => (
  <>
    <h3 className="tw-text-xl tw-font-bold tw-mt-4 tw-mb-2">
      The auction end time was extended.
    </h3>
    <p>
      To give other bidders a chance to place a bid before the auction ends, we
      have extended the auction end time
      {sale.endDate && (
        <>
          {' '}
          to <strong>{dayjs(sale.endDate).format('h:mm:ss A')}</strong>
        </>
      )}
      .
    </p>
  </>
);

const SolicitDisplayName = ({ onClose }) => {
  return (
    <>
      <ModalTitle>Set your Marketplace username</ModalTitle>
      <p id="modal-description">
        Please choose a unique Namecheap Market username which will show up when
        you bid or comment on auctions. You can only set it one time.
      </p>
      <div className="tw-mt-6">
        <DisplayNameForm onSuccess={onClose} showEditForm />
      </div>
      <div className="tw-mt-10">
        <DismissButton messageId="solicit_display_name" onClose={onClose}>
          Don’t ask me again
        </DismissButton>
      </div>
    </>
  );
};

const SolicitDisplayNameForComment = ({ onClose }) => {
  return (
    <>
      <ModalTitle>Set your Marketplace username</ModalTitle>
      <p id="modal-description">
        You need to pick your own unique Marketplace username to be able to
        comment on auctions.
      </p>
      <p className="tw-mt-3">You can only set it one time ever.</p>
      <div className="tw-mt-6 tw-mb-10">
        <DisplayNameForm onSuccess={onClose} showEditForm />
      </div>
      <button
        className="tw-text-secondary hover:tw-underline"
        onClick={onClose}
        type="button">
        No Thanks
      </button>
    </>
  );
};

const FailedToAddDomainToCart = ({ data, onClose }) => {
  return (
    <>
      <ModalTitle>
        Please reach out to us directly to purchase {data.domain}
      </ModalTitle>
      <p className="tw-mb-8" id="modal-description">
        Sorry, we were unable to add {data.domain} to the shopping cart. Please
        reach out to use directly and someone from our team will assist you with
        this purchase, thank you.
      </p>
      <ModalActions justify="end">
        <a
          href="https://www.namecheap.com/help-center/live-chat"
          className="tw-px-4 gb-btn gb-btn--lg gb-btn--secondary"
          onClick={onClose}>
          Go to Live Chat
        </a>
      </ModalActions>
    </>
  );
};

const DismissButton = ({ children, messageId, onClose }) => {
  const [dismissMessage] = useMutation(queryDefs.dismissMessage);
  const onDismiss = async () => {
    try {
      await dismissMessage({
        variables: {
          messageId,
        },
      });
      onClose();
    } catch (error) {
      window.alert('Request failed.');
    }
  };
  return (
    <button className="gb-btn gb-btn--sm" onClick={onDismiss} type="button">
      {children}
    </button>
  );
};
