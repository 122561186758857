import cx from 'classnames';

export default function Button({
  children = null,
  className = '',
  component = null,
  innerLeft = null,
  innerRight = null,
  size = 'medium',
  theme = 'primary',
  type = null,
  variant = 'filled',
  ...props
}) {
  const baseClassName = cx(
    className,
    'tw-no-underline',
    'tw-inline-flex tw-items-center tw-justify-center',
    'tw-rounded',
    'tw-whitespace-nowrap tw-font-bold',
    'tw-leading-none',
    {
      'tw-px-1 tw-h-8 tw-text-sm': size === 'small',
      'tw-px-1 tw-h-10 tw-text-base': size === 'medium',
      'tw-px-1 tw-h-12 tw-text-base': size === 'large',
      'tw-px-2 tw-h-12 md:tw-h-16 tw-text-xl md:tw-text-2xl': size === 'huge',
      'tw-opacity-50 tw-cursor-default': props.disabled === true,
    },
  );

  const filledClassName = cx('tw-text-white', {
    'gb-btn--primary': theme === 'primary',
    'gb-btn--secondary': theme === 'secondary',
  });

  const outlinedClassName = cx({
    'tw-border-primary tw-text-primary hover:tw-text-primary-dark hover:tw-border-secondary-dark':
      theme === 'primary',
    'tw-border-secondary tw-text-secondary hover:tw-text-secondary-dark hover:tw-border-secondary-dark':
      theme === 'secondary',
    'tw-border-gray-600 tw-text-gray-600 hover:tw-border-gray-700 hover:tw-text-gray-700':
      theme === 'tertiary',
  });

  const buttonClassName = cx(baseClassName, {
    [filledClassName]: variant === 'filled',
    [outlinedClassName]: variant === 'outlined',
  });

  const content = (
    <div
      className={cx('tw-flex tw-items-center', {
        'tw-px-2': !(innerLeft || innerRight),
      })}>
      {innerLeft && <div className="tw-mr-2">{innerLeft}</div>}
      {children}
      {innerRight && <div className="tw-ml-2">{innerRight}</div>}
    </div>
  );

  if (component) {
    const Component = component;
    return (
      <Component className={buttonClassName} {...props}>
        {content}
      </Component>
    );
  }

  return (
    <button className={buttonClassName} type={type || 'button'} {...props}>
      {content}
    </button>
  );
}
