import { useCallback, useState } from 'react';

const safeKey = key => `${process.env.REACT_APP_PACKAGE_NAME}:${key}`;

export function getStoredValue(key) {
  // Get from local storage by key
  const item = window.localStorage.getItem(safeKey(key));
  // Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : undefined;
}

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = getStoredValue(key);
      // Parse stored json or if none return initialValue
      return item || initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    value => {
      try {
        // Save state
        setStoredValue(value);
        // Save to local storage
        window.localStorage.setItem(safeKey(key), JSON.stringify(value));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key],
  );

  return [storedValue, setValue];
}
