import { NavLink } from 'react-router-dom';
import { ReactComponent as BuyNow } from '../assets/icons/buy-now.svg?ReactComponent';
import { ReactComponent as Dashboard } from '../assets/icons/dashboard.svg?ReactComponent';
import { ReactComponent as MarketHome } from '../assets/icons/market-home.svg?ReactComponent';
import { ReactComponent as Stopwatch } from '../assets/icons/stopwatch.svg?ReactComponent';
import { useApolloClient } from '@apollo/client';

const CustomNavLink = ({ className = '', to, ...props }) => (
  <NavLink
    className={isActive =>
      `tw-inline-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center md:tw-px-4 ${
        isActive ? 'tw-text-secondary' : 'tw-text-gray-600'
      } hover:tw-text-secondary active:tw-text-secondary-dark tw-no-underline ${className}`
    }
    to={to}
    {...props}
  />
);

export default function Nav() {
  const client = useApolloClient();
  return (
    <div className="tw-bg-white tw-border-b tw-border-gray-100">
      <div className="tw-wrap">
        <div className="tw-wrap-inner">
          <nav className="md:tw--mx-4 tw-flex tw-items-center sm:tw-items-stretch tw-h-16 tw-font-bold tw-whitespace-nowrap tw-text-xs sm:tw-text-base">
            <div className="tw-flex tw-justify-center md:tw-justify-start tw-w-1/4">
              <CustomNavLink exact to="/">
                <MarketHome className="sm:tw-mr-2" />
                Market
              </CustomNavLink>
            </div>
            <div className="tw-flex-auto tw-justify-around md:tw-justify-center tw-flex md:tw-text-lg">
              <CustomNavLink
                activeClassName="tw-nav-tab--active"
                className="tw-nav-tab md:tw-mr-6"
                to="/auctions/"
                onClick={() => client.resetStore()}>
                <Stopwatch className="sm:tw-mr-2" />
                Auctions
              </CustomNavLink>
              <CustomNavLink
                activeClassName="tw-nav-tab--active"
                className="tw-nav-tab"
                to="/buynow/">
                <BuyNow className="tw-h-6 sm:tw-mr-2" />
                Buy Now
              </CustomNavLink>
            </div>
            <div className="tw-flex tw-justify-center md:tw-justify-end tw-w-1/4">
              <CustomNavLink to="/dashboard/">
                <Dashboard className="sm:tw-mr-1" />
                <span className="tw-sr-only lg:tw-not-sr-only">Market</span>
                &nbsp;Dashboard
              </CustomNavLink>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
