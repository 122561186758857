import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { addToCart } from '../lib/api';
import cartItemsVar from '../lib/cartItemsVar';
import AppDialog from './AppDialog';
import Modal from './Modal';

export default function AddToCartButton({ children, sales }) {
  const [busy, setBusy] = useState(false);
  const [progress, setProgress] = useState(0);
  const cartItems = useReactiveVar(cartItemsVar);
  const isBulk = sales.length > 1;
  const inCart = sales.every(sale => cartItems.includes(sale.product.name));
  const reset = () => {
    setProgress(0);
    setBusy(false);
  };

  useEffect(() => {
    const finishedProcessing = progress === sales.length;
    if (!busy) {
      if (progress !== 0 && !finishedProcessing) {
        document.dispatchEvent(
          new CustomEvent('domainSearch.cancelAddAllToCart', {
            detail: progress,
          }),
        );
      }
    } else if (finishedProcessing) {
      if (isBulk) {
        document.dispatchEvent(
          new CustomEvent('domainSearch.addAllToCart', {
            detail: sales.length,
          }),
        );
      } else {
        document.dispatchEvent(
          new CustomEvent('domainSearch.addToCart', {
            detail: sales[0].product.name,
          }),
        );
      }
      reset();
    } else {
      const cartItems = cartItemsVar();
      const sale = sales[progress];
      if (cartItems.includes(sale.product.name)) {
        setProgress(i => i + 1);
      } else {
        addToCart(sale)
          .then(() => {
            setProgress(i => i + 1);
            cartItemsVar([sale.product.name, ...cartItemsVar()]);
          })
          .catch(error => {
            console.error(error);
            document.dispatchEvent(
              new CustomEvent(AppDialog.events.open, {
                detail: {
                  key: 'failedToAddDomainToCart',
                  domain: sale.product.name,
                },
              }),
            );
            if (progress > 0) {
              document.dispatchEvent(
                new CustomEvent('domainSearch.cancelAddAllToCart', {
                  detail: progress,
                }),
              );
            }
            reset();
          });
      }
    }
  }, [busy, isBulk, progress, sales]);

  return (
    <>
      {children({ busy, inCart, onClick: () => setBusy(true) })}
      {isBulk ? (
        <Modal
          onClose={() => {
            setBusy(false);
          }}
          open={busy}>
          <h2
            className="tw-text-2xl tw-font-bold tw-text-gray-700 tw-mb-2"
            id="modal-title">
            Adding Domains
          </h2>
          <p id="modal-description">
            The domains you selected are being added to your shopping cart. This
            may take a couple minutes.
          </p>
          <div className="tw-my-6 tw-border tw-border-gray-400 tw-rounded tw-overflow-hidden">
            <div className="tw-flex tw-items-center tw-p-4">
              <div className="tw-flex-auto">
                Processing:
                <span className="tw-ml-2 tw-text-gray-700 tw-font-medium">
                  {progress} of {sales.length} domains
                </span>
              </div>
              <div className="gb-loader gb-loader--secondary" />
            </div>
            <LinearProgress
              color="secondary"
              value={Math.floor((progress / sales.length) * 100)}
              variant="determinate"
            />
          </div>
          <div className="tw-flex tw-justify-end tw-mt-4">
            <button
              className="gb-btn gb-btn--lg"
              onClick={() => {
                setBusy(false);
              }}>
              Cancel
            </button>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
