export default function SaleTableFetchMoreButton({ onClick }) {
  return (
    <button
      className="gb-btn gb-btn--lg gb-btn--block"
      type="button"
      onClick={onClick}>
      Load More
    </button>
  );
}
