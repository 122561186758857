import { useEffect, useState } from 'react';

export default function useIsTouchDevice() {
  const [isTouchDevice, setIsTouchDevice] = useState(null);
  useEffect(() => {
    const mql = window.matchMedia('(pointer: fine)');
    const handleMql = ({ matches }) => {
      setIsTouchDevice(!matches);
    };
    handleMql(mql);
    if (mql.addEventListener) {
      mql.addEventListener('change', handleMql);
    } else if (mql.addEventListener) {
      mql.addEventListener(handleMql);
    }
    return () => {
      if (mql.removeEventListener) {
        mql.removeEventListener('change', handleMql);
      } else if (mql.removeEventListener) {
        mql.removeEventListener(handleMql);
      }
    };
  }, []);
  return isTouchDevice;
}
