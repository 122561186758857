import cx from 'classnames';
import { useContext, useRef } from 'react';
import { ReactComponent as WatchIcon } from '../assets/icons/star.svg';
import useFavoriteMutation from '../lib/useFavoriteMutation';
import { UserContext } from './App';
import Checkbox from './Checkbox';
import Loader from './Loader';
import { useSaleTable } from './SaleTable';
import Table, { TableCell, TableRow } from './Table';

export default function SaleTableRows({ showWatch = true }) {
  const node = useRef();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    loading,
    rows,
    prepareRow,
  } = useSaleTable();
  const [favorite, favoriteQuery] = useFavoriteMutation();
  const userContext = useContext(UserContext);

  return (
    <div className="tw-relative">
      <div className="tw-overflow-auto" ref={node}>
        <div>
          <Table {...getTableProps()} className="tw-overflow-hidden">
            <thead>
              {headerGroups.map(headerGroup => (
                <TableRow
                  header
                  {...headerGroup.getHeaderGroupProps()}
                  className="tw-h-14 touch:tw-h-16">
                  {headerGroup.headers.map(column => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="tw-select-none tw-group tw-trasition-colors tw-duration-75 tw-ease"
                      header
                      title="Sort results by column">
                      <div className="tw-flex tw-items-center">
                        <div
                          className={
                            column.canSort ? 'group-hover:tw-text-black' : ''
                          }>
                          {column.render('Header')}
                        </div>
                        {column.canSort ? (
                          <i
                            className={cx(
                              'tw-ml-2',
                              column.isSorted ? '' : 'tw-invisible',
                              `gb-icon gb-icon-arrow-${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? 'down'
                                    : 'up'
                                  : column.sortDescFirst
                                  ? 'down'
                                  : 'up'
                              }`,
                            )}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const isFavorited = userContext.favoriteSaleIds?.includes(
                  row.original.id,
                );
                return (
                  <TableRow
                    {...row.getRowProps()}
                    className="tw-h-14 touch:tw-h-16"
                    isSelected={row.isSelected}>
                    {row.cells.map((cell, index) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {index === 0 ? (
                            <div className="tw-flex tw-h-14 touch:tw-h-16">
                              <div className="tw-hidden md:tw-flex">
                                <Checkbox
                                  className="tw-mr-3 tw-self-center"
                                  {...row.getToggleRowSelectedProps()}
                                />
                              </div>
                              {showWatch && (
                                <button
                                  className={cx(
                                    'tw-inline-flex tw-items-center tw-mr-3 tw-text-gray-400 tw-self-center',
                                    { 'tw-cursor-wait': favoriteQuery.loading },
                                  )}
                                  disabled={favoriteQuery.loading}
                                  onClick={async () => {
                                    try {
                                      await favorite({
                                        [isFavorited ? 'remove' : 'add']: [
                                          row.original.id,
                                        ],
                                      });
                                    } catch (error) {
                                      window.alert(
                                        `Failed to ${
                                          isFavorited
                                            ? 'remove domain from favorites.'
                                            : 'add domain to favorites.'
                                        }`,
                                      );
                                    }
                                  }}
                                  title={
                                    isFavorited
                                      ? 'Remove domain from favorites'
                                      : 'Add domain to favorites'
                                  }>
                                  <WatchIcon
                                    className={cx(
                                      'tw-w-5 tw-h-5 hover:tw-text-orange',
                                      {
                                        'tw-text-orange tw-fill-current':
                                          isFavorited,
                                      },
                                    )}
                                  />
                                </button>
                              )}
                              <div className="tw-flex-auto tw-flex">
                                {cell.render('Cell')}
                              </div>
                            </div>
                          ) : (
                            cell.render('Cell')
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </div>
        {loading && (
          <Loader
            className={cx(
              'tw-py-24 tw-bg-white tw-bg-opacity-50 tw-pointer-events-none',
              {
                'tw-absolute tw-inset-0': rows.length > 0,
              },
            )}
            message={rows.length > 0 ? '' : undefined}
          />
        )}
      </div>
    </div>
  );
}
