import _flatten from 'lodash/flatten';
import { useApolloClient, useQuery } from '@apollo/client';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import Meta from '../components/Meta';
import SaleCarousel from '../components/SaleCarousel';
import getSalePresenter from '../lib/getSalePresenter';
import queryDefs from '../lib/queryDefs';
import getSaleCarouselPropsFromCollection from '../lib/getSaleCarouselPropsFromCollection';

export default function Home() {
  const client = useApolloClient();

  const { data, error, loading } = useQuery(queryDefs.marketHome, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5 * 60 * 1000, // 5 minutes
    onCompleted: () => {
      client.refetchQueries({ include: ['SalesEndingSoonRealtimeSync'] });
    },
  });

  const endingSoon = _flatten(
    data?.collections?.map(collection => collection.sales.items) ?? [],
  )
    .map(sale => getSalePresenter(sale))
    .filter(
      presenter =>
        presenter.endDate &&
        (presenter.isRecentlyEnded || // Include recently ended sales since this query is cached
          (presenter.secondsRemaining > 0 &&
            presenter.secondsRemaining <= 3600)),
    );

  useQuery(queryDefs.salesRealtimeSync, {
    pollInterval: 5 * 1000, // 5 seconds
    skip: endingSoon.length === 0,
    variables: {
      ids: [...new Set(endingSoon.map(sale => sale.id))],
    },
  });

  if (!data && loading) {
    // Add a huge bottom padding to avoid bumping user to bottom of page due to large footer on namecheap.com
    return <Loader className="tw-pt-48 tw-pb-[9999px]" />;
  }

  if (!data && error) {
    // Only if no data, to avoid showing errors on stale browser tabs
    return (
      <div className="tw-wrap tp-px-4 tw-pt-4">
        <Alert severity="error" title="Failed to load data" />
      </div>
    );
  }

  return (
    <div className="tw-bg-near-white tw-py-1">
      <Meta
        canonical="https://www.namecheap.com/market/"
        description="Namecheap Marketplace is the place to buy and sell domain names at auction. Buy domains instantly or bid in a domain auction to grab the perfect domain name."
        robots="all"
        title="Buy &amp; Sell Domains - Domain Name Auctions | Namecheap"
      />
      {Object.values(data?.collections || {}).map(collection => (
        <SaleCarousel
          className="tw-my-10 xl:tw-my-12"
          containerClassName="tw-grid-cols-[1fr_min(100%,1440px)_1fr] tw-px-4"
          key={collection.slug}
          {...getSaleCarouselPropsFromCollection(collection)}
        />
      ))}
    </div>
  );
}
