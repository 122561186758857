import { Suspense, lazy, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import useLocalStorage from '../lib/useLocalStorage';
import isLoggedInVar from '../lib/isLoggedInVar';
import { UserContext } from './App';

const NewsletterSignUpCTA = lazy(() => import('./NewsletterSignUpCTA'));
const WelcomeChecklist = lazy(() => import('./WelcomeChecklist'));

export default function AppCTA() {
  const userContext = useContext(UserContext);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const location = useLocation();
  const [welcomeHidden, setWelcomeHidden] = useLocalStorage(
    'WelcomeChecklist:hidden',
    false,
  );
  const [newsletterHidden, setNewsletterHidden] = useLocalStorage(
    'NewsletterSignUpCTA:hidden',
    false,
  );

  const welcomeConditions = [
    userContext.status === 'verified',
    Boolean(userContext.subscription),
    !Boolean(userContext.minAccountFundsRequired),
  ];

  const allConditionsSatisfied = welcomeConditions.every(
    condition => condition,
  );

  useEffect(() => {
    setWelcomeHidden(allConditionsSatisfied);
  }, [allConditionsSatisfied, setWelcomeHidden]);

  // Detemine which CTA to show
  let cta;
  const hiddenOnPaths = ['/subscribe', '/newsletter', '/login', '/logout'];

  if (hiddenOnPaths.some(path => location.pathname.startsWith(path))) {
    cta = null;
  } else if (isLoggedIn === true && !welcomeHidden) {
    cta = <WelcomeChecklist />;
  } else if (isLoggedIn !== null && !newsletterHidden) {
    cta = <NewsletterSignUpCTA onHide={() => setNewsletterHidden(true)} />;
  }

  return <Suspense>{cta}</Suspense>;
}
