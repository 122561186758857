import cx from 'classnames';
import { forwardRef } from 'react';

const Badge = forwardRef(
  (
    /** @type {any} */
    {
      className = 'tw-bg-gray-100',
      children = null,
      tagName = 'div',
      ...props
    },
    ref,
  ) => {
    const TagName = tagName;
    return (
      <TagName
        {...props}
        className={cx(
          className,
          'tw-inline-flex tw-font-bold tw-rounded tw-uppercase tw-leading-none tw-p-[0.4em]',
        )}
        ref={ref}>
        {children}
      </TagName>
    );
  },
);

export default Badge;
