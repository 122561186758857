import cx from 'classnames';

export default function Alert({
  children = null,
  className = '',
  error = null,
  severity = 'warning',
  title = 'Something went wrong',
}) {
  if (error?.networkError) {
    title = 'Network error';
    severity = 'warning';
    children = (
      <p>
        A network error has occurred. Please check your connection or press
        'Reload' in your browser.
      </p>
    );
  }
  return (
    <div className={cx(`gb-alert gb-alert--${severity}`, className)}>
      <div className="gb-alert__body">
        <div className="gb-alert__icon">
          <i className={`gb-icon gb-icon-morpheus-${severity}-full`} />
        </div>
        <div className="gb-alert__content">
          <div className="gb-alert__title">{title}</div>
          {children}
        </div>
      </div>
    </div>
  );
}
