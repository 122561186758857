export default function getAuthRedirectUrl(returnTo) {
  const { origin } = window.location;
  let result =
    `https://${process.env.REACT_APP_SSO_DOMAIN}` +
    `/connect/authorize` +
    `?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}` +
    `&redirect_uri=${origin}/market/login/` +
    `&response_type=code` +
    `&scope=openid offline_access productPlatform.accountBalance.read`;
  if (returnTo) {
    result += `&state=${returnTo}`;
  }
  return result;
}
