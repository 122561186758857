import _keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import columnDefs from '../components/SaleTable.columns';

const COLUMNS_BY_ID = _keyBy(columnDefs, 'id');

export default function useSaleTableState(defaultState = {}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const globalFilter =
    searchParams.get('query') ?? defaultState.globalFilter ?? '';

  let filters = defaultState.filters;
  let sortBy = defaultState.sortBy;

  if (searchParams.get('filter') !== null) {
    try {
      filters = JSON.parse(atob(searchParams.get('filter')));
    } catch (error) {
      console.error(
        'Failed to parse filters from URL',
        searchParams.get('filter'),
      );
    }
  }

  if (searchParams.get('sort') !== null) {
    try {
      sortBy = JSON.parse(atob(searchParams.get('sort')));
    } catch (error) {
      console.error('Failed to parse sort from URL', searchParams.get('sort'));
    }
  }

  return {
    api: {
      filter: useMemo(() => {
        const result = {};
        (filters || []).forEach(filter => {
          const column = COLUMNS_BY_ID[filter.id];
          const filterKey = column.filterQuery || column.id;
          if (column.filter === 'between') {
            result[filterKey] = {
              min: filter.value[0] ?? undefined,
              max: filter.value[1] ?? undefined,
            };
          } else {
            result[filterKey] = filter.value;
          }
        });
        if (globalFilter) {
          result.keywords = globalFilter;
        }
        return result;
      }, [filters, globalFilter]),
      sort: useMemo(() => {
        return (sortBy || []).map(({ id: column, desc }) => ({
          column,
          direction: desc ? 'desc' : 'asc',
        }));
      }, [sortBy]),
    },
    filters: filters || [],
    globalFilter,
    sortBy: sortBy || [],
  };
}
