import cx from 'classnames';
import _uniqueId from 'lodash/uniqueId';

export default function Checkbox({
  className = '',
  label,
  indeterminate = 'false',
  id = _uniqueId('checkbox'),
  ...props
}) {
  return (
    <div className={cx('gb-checkbox gb-checkbox--sm tw-flex', className)}>
      <input
        className="gb-checkbox__input"
        id={id}
        indeterminate={String(indeterminate)}
        type="checkbox"
        {...props}
      />
      <label
        className="gb-checkbox__label tw-relative tw--m-2 tw-p-2 tw-top-[2px]"
        htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
