import cx from 'classnames';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import useLocalStorage from '../lib/useLocalStorage';
import TextInput from './TextInput';
import { useSaleTable } from './SaleTable';

const filterConfigs = [
  {
    column: 'price',
    name: 'Price',
  },
  {
    column: 'nameLength',
    name: 'Name length',
    className: 'tw-col-span-1',
  },
  {
    column: 'tld',
    className: 'tw-col-span-1 sm:tw-col-span-2 md:tw-col-span-1',
    name: 'Extensions',
  },
  {
    name: 'Other options',
    className:
      'tw-col-span-1 sm:tw-col-span-2 md:tw-col-span-1 lg:tw-col-span-1 xl:tw-col-span-2',
    facets: [
      {
        column: 'noNumbers',
      },
      {
        column: 'onlyNumbers',
      },
      {
        column: 'noHyphens',
      },
      {
        column: 'nsfw',
      },
    ],
  },
];

export default function SaleTableToolbar({ hiddenFilters = [] }) {
  const [showFilters, setShowFilters] = useLocalStorage(
    'SaleTable:showFilters',
    false,
  );
  const {
    allColumns,
    loading,
    setAllFilters,
    setGlobalFilter,
    totalItems,
    state: { filters, globalFilter },
  } = useSaleTable();
  const setGlobalFilterDebounced = useAsyncDebounce(
    value => setGlobalFilter(value),
    200,
  );
  const [searchInput, setSearchInput] = useState(globalFilter);
  return (
    <div className="tw-py-5">
      <div className="tw-relative">
        <div className="tw-flex tw-items-center">
          <button
            className={cx(
              {
                'tw-paper': !showFilters,
              },
              'tw-group tw-relative tw-box-content tw-z-10 tw-inline-flex tw-items-center tw-justify-center tw-w-20 tw-h-12 tw-pl-2 tw-pr-4 tw-font-bold tw-rounded-lg',
            )}
            onClick={() => setShowFilters(!showFilters)}
            type="button">
            <i
              className={cx(
                'tw-text-2xl tw-text-secondary group-hover:tw-text-secondary-dark tw-mr-2 gb-icon',
                {
                  'gb-icon-morpheus-minus-full': showFilters,
                  'gb-icon-morpheus-plus-circle': !showFilters,
                },
              )}
            />
            Filter
          </button>
          <div className="tw-flex tw-flex-wrap tw-flex-auto sm:tw-flex-none tw-ml-4 tw-whitespace-nowrap tw-gap-3">
            {loading ? (
              <span className="tw-text-sm sm:tw-text-base">loading...</span>
            ) : Number.isInteger(totalItems) ? (
              <span className="tw-text-sm sm:tw-text-base">
                {totalItems.toLocaleString()} domains
              </span>
            ) : (
              ''
            )}
            {!loading && (filters.length > 0 || globalFilter) && (
              <button
                className="tw-inline-flex tw-items-center tw-text-sm tw-text-secondary tw-group"
                onClick={() => {
                  setAllFilters([]);
                  setGlobalFilter('');
                  setSearchInput('');
                }}>
                <i className="gb-icon gb-icon-morpheus-cross-small" />
                <span className="tw-ml-px tw-lowercase group-hover:tw-underline tw-whitespace-nowrap">
                  Clear filters
                </span>
              </button>
            )}
          </div>
        </div>
        {showFilters && (
          <div className="tw-filters tw-brand-shadow">
            <div className="tw-grid tw-gap-px md:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-grid-cols-5 tw-bg-gray-100 tw-rounded-lg tw-overflow-hidden">
              {filterConfigs
                .filter(filter => hiddenFilters.indexOf(filter.column) === -1)
                .map(filter => (
                  <div
                    className={cx(
                      filter.className,
                      'tw-px-5 tw-py-5 sm:tw-py-6 md:tw-py-8 tw-bg-white',
                    )}
                    key={filter.name}>
                    <div className="tw-text-sm">{filter.name}</div>
                    <div className="tw-mt-2">
                      {filter.facets ? (
                        <div className="tw-flex tw-gap-y-1 tw-gap-x-4 tw-flex-wrap">
                          {filter.facets
                            .filter(f => hiddenFilters.indexOf(f.column) === -1)
                            .map(f => (
                              <div
                                className="tw-flex tw-items-center"
                                key={f.column}>
                                {allColumns
                                  .find(column => column.id === f.column)
                                  .render('Filter')}
                              </div>
                            ))}
                        </div>
                      ) : (
                        allColumns
                          .find(column => column.id === filter.column)
                          .render('Filter')
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className="md:tw-absolute tw-top-0 tw-right-0 tw-flex tw-items-center tw-mt-5 md:tw-mt-0 tw-overflow-hidden">
          <div className="tw-flex-auto">
            <form onSubmit={event => event.preventDefault()}>
              <TextInput
                className="md:tw-w-56"
                inputClassName="tw-pr-6"
                innerRight={
                  <i className="gb-icon gb-icon-morpheus-search tw-mx-2" />
                }
                minLength={2}
                onChange={({ target }) => {
                  setSearchInput(target.value);
                  target.setCustomValidity('');
                  if (target.value.length !== 1)
                    setGlobalFilterDebounced(target.value);
                }}
                onInvalid={({ target }) => {
                  target.setCustomValidity(
                    'Please enter at least two characters.',
                  );
                }}
                placeholder="Search"
                size="small"
                value={searchInput}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
