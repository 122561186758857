import { styled } from '@mui/material/styles';
import MUISlider from '@mui/material/Slider';

const PREFIX = 'MuiSlider';

const classes = {
  root: `${PREFIX}-root`,
  thumb: `${PREFIX}-thumb`,
  active: `${PREFIX}-active`,
  valueLabel: `${PREFIX}-valueLabel`,
  track: `${PREFIX}-track`,
  rail: `${PREFIX}-rail`,
};

const StyledMUISlider = styled(MUISlider)({
  [`&.${classes.root}`]: {
    display: 'block',
    height: 2,
    width: 'auto',
    margin: '0 6px',
    padding: '13px 0',
    '@media (pointer: coarse)': {
      margin: '0 8px',
      padding: '20px 0',
    },
  },
  [`& .${classes.thumb}`]: {
    height: 12,
    width: 12,
    // backgroundColor: '#fff',
    border: '2px solid currentColor',
    '@media (pointer: coarse)': {
      height: 16,
      width: 16,
    },
  },
  [`& .${classes.thumb}::before`]: {
    boxShadow: 'none',
  },
  [`& .${classes.active}`]: {},
  [`& .${classes.valueLabel}`]: {
    left: 'calc(-50% + 4px)',
  },
  [`& .${classes.track}`]: {
    height: 2,
  },
  [`& .${classes.rail}`]: {
    color: '#f2f2f2',
    opacity: 1,
    height: 2,
  },
});

export default function Slider({ color = 'secondary', ...props }) {
  return <StyledMUISlider color={color} {...props} />;
}
