import Meta from '../components/Meta';
import { SaleTableColumnSets } from '../components/SaleTable.columns';
import SaleTablePage from '../components/SaleTablePage';
import queryDefs from '../lib/queryDefs';

export default function BuyNow() {
  return (
    <>
      <Meta
        canonical="https://www.namecheap.com/market/"
        description="Find domain names for sale and purchase them right away in our Buy Now section. It’s your chance to get a great deal today."
        robots="all"
        title="Buy premium domains - Namecheap"
      />
      <div className="tw-pb-8">
        <SaleTablePage
          fetchPolicy="cache-first"
          hiddenFilters={['nsfw']}
          nextFetchPolicy="cache-first"
          query={queryDefs.buyNowTable}
          salesPath="binSales"
          showAddToCart={true}
          showWatch={false}
          title="Buy Now"
          visibleColumns={SaleTableColumnSets.buynow}
        />
      </div>
    </>
  );
}
