import hero from '../assets/hero-app.svg';

export default function Layout({ children }) {
  return (
    <>
      <section className="gb-hero tw-z-0">
        <div className="gb-hero__image-holder">
          <img alt="" src={hero} />
        </div>
        <div className="gb-hero__body">
          <h1>Market</h1>
          <p>Discover domains for sale in our Auctions and Buy Now listings</p>
        </div>
      </section>
      {children}
    </>
  );
}
