Object.defineProperty(exports, '__esModule', {
  value: true,
});

// Simple compare function to be passed to Array.sort for ensuring consistent order of definitions in queries. Ensures client and server will generate the same query hash.
module.exports = function sortDocumentDefinitions(a, b) {
  if (a.kind === 'OperationDefinition') return -1;
  if (b.kind === 'OperationDefinition') return 1;
  if (a.name.value < b.name.value) return -1;
  if (a.name.value > b.name.value) return 1;
  return 0;
};
