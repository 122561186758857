import { Helmet } from 'react-helmet';

export default function Meta({
  canonical = '',
  description = '',
  title = '',
  robots = 'noindex, nofollow',
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      {robots && <meta name="robots" content={robots} />}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
}
