import cx from 'classnames';

export default function Table({ className = '', ...props }) {
  return (
    <table className={cx(className, 'tw-w-full tw-text-left')} {...props} />
  );
}

export function TableCell({ className = '', header = false, ...props }) {
  const Component = header ? 'th' : 'td';
  return (
    <Component
      className={cx(className, 'tw-px-4 md:tw-px-6 tw-whitespace-nowrap')}
      {...props}
    />
  );
}

export function TableRow({
  className = '',
  header = false,
  isSelected = false,
  ...props
}) {
  return (
    <tr
      className={cx(className, 'tw-border-b', {
        'tw-border-gray-100 tw-bg-white': !isSelected,
        'tw-bg-secondary-lighter tw-border-secondary-lighter': isSelected,
        'hover:tw-bg-secondary hover:tw-bg-opacity-5': !isSelected && !header,
      })}
      {...props}
    />
  );
}
