import { useContext } from 'react';
import Alert from './Alert';
import { UserContext } from './App';

export default function AppStatus() {
  const { status } = useContext(UserContext);
  return (
    <div className="tw-wrap">
      <div className="tw-mx-auto tw-max-w-screen-lg">
        {status === 'banned' && (
          <Alert className="tw-mt-4" title="Bidding is currently disabled" />
        )}
      </div>
    </div>
  );
}
