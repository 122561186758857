import { BrowserRouter } from 'react-router-dom';
import SmoothScroll from 'smoothscroll-polyfill';
import { ApolloProvider } from '@apollo/client';
import getApolloClient from '../lib/getApolloClient';
import AppGateway from './AppGateway';
import 'react-phone-number-input/style.css';
import '../styles/greenbeard.scss';
import '../styles/app.css';

SmoothScroll.polyfill();

export default function Root() {
  return (
    <ApolloProvider client={getApolloClient()}>
      <BrowserRouter basename="/market">
        <AppGateway />
      </BrowserRouter>
    </ApolloProvider>
  );
}
