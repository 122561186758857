import cx from 'classnames';
import { Link } from 'react-router-dom';

export default function Card({
  children,
  className,
  link,
  component = Link,
  ...props
}) {
  const Component = component;
  return (
    <Component
      className={cx(
        className,
        'tw-flex tw-flex-col tw-p-5 tw-paper md:tw-text-lg tw-no-underline tw-text-gray-600',
        'hover:tw-ring-2 tw-ring-opacity-75 tw-ring-gray-400 tw-transition-shadow tw-duration-100 tw-ease-out',
      )}
      to={link}
      {...props}>
      {children}
    </Component>
  );
}

export function CardFooter({ children, className }) {
  return (
    <div
      className={cx(
        className,
        'tw-flex-auto tw-flex tw-items-end tw-justify-between tw-mt-8',
      )}>
      {children}
    </div>
  );
}

export function CardTitle({ children }) {
  return (
    <div className="tw-text-gray-700 tw-text-xl md:tw-text-2xl tw-font-bold tw-leading-tight tw-break-words tw-overflow-hidden">
      {children}
    </div>
  );
}
