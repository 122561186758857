import cx from 'classnames';

export default function Loader({
  className = 'tw-py-48',
  message = 'Please wait…',
}) {
  return (
    <div className={cx('tw-flex tw-flex-col tw-items-center', className)}>
      <div className="gb-loader" />
      {message && (
        <div className="tw-mt-4 tw-text-gray-500">&nbsp; {message}</div>
      )}
    </div>
  );
}
